.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-fluid .jumbotron, .container .jumbotron {
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 768px)
{
.container-fluid .jumbotron, .container .jumbotron {
    padding-left: 60px;
    padding-right: 60px;
}
}

@media screen and (min-width: 768px)
{
.jumbotron {
    padding-bottom: 48px;
    padding-top: 48px;
}
}

.jumbotron {
  background-color: #eee;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.custom-quill .ql-container {
  height: 80vh !important;
  overflow-y: scroll;
  resize: vertical;
}